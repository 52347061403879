<script setup>

  /**
   * 
   * Komponens: modal + elfelejtett jelszó form
   * 
  */

  // Form and validation
  import * as yup from 'yup';

  // pinia stores
  import { storeToRefs } from 'pinia';
  import { useModalAuthForgetPasswordStore } from "~/store/modalauthforgetpassword";
  import { useAuthStore } from "~/store/auth";

  // auth
  const authStore = useAuthStore();
  const { forgotPassword, forgotPasswordSubmit } = authStore;
  const { actionerror, actionerrordetails } = storeToRefs(authStore);
  // forgotted passwd
  const modalAuthForgetPasswordStore = useModalAuthForgetPasswordStore();
  const { closeModal } = modalAuthForgetPasswordStore; // all non-reactive stuff
  const { isOpened } = storeToRefs(modalAuthForgetPasswordStore) // have all reactive states

  // nyelvi es routing beallitasok
  // const localePath = useLocalePath();
  // const { t, locale } = useI18n();
  const { t } = useI18n();
  // const route = useRoute();
  // const router = useRouter();

  const loading = ref(false);
  const loadinginmodal = ref(false);
  let process = ref(1);
  let alreadySent = ref(false);
  let email = ref('');
  let form02Values = ref({});
  let password = ref('');
  let passwordconfirm = ref('');
  let errorSummary = ref('');

  // Ezt a DynamicForm komponens kapja meg mint prop,
  //  és akkor hívja meg, ha egy input mező értéke változik
  const fieldChangeForgotFormProcess2 = (name, value) => {
    if(name === 'passwordconfirm') {
      passwordconfirm.value = value
    }
    if(name === 'password') {
      password.value = value
    }
  };

  watch(() => passwordconfirm.value, async (newVal, oldVal) => {
    if(newVal !== oldVal) {
      formSchema02.fields[3].rules = yup.string().required(t('yup.required')).test('passwords-are-same', 'Passwords does not match', () => {
        return password.value === passwordconfirm.value;
      }).label(t('New password again'))
    }
  });

  watch(() => password.value, async (newVal, oldVal) => {
    if(newVal !== oldVal) {
      formSchema02.fields[3].rules = yup.string().required(t('yup.required')).test('passwords-are-same', 'Passwords does not match', () => {
        return password.value === passwordconfirm.value;
      }).label(t('New password again'))
    }
  });

  // 
  const formSchema01 = {
    fields: [
    {
        id: 'email',
        label: t('E-mail address') + ' *',
        hint: '',
        placeholder: t('E-mail address'),
        name: 'email',
        as: 'input',
        type: 'email',
        value: email.value || '',
        validateOnInput: true,
        validateOnMount: true,
        validateOnChange: true,
        // initialValue: authenticated.value ? '' : '',
        rules: yup.string().required(t('yup.required')).email(t('yup.email')).min(8, t('yup.min')).max(100).label(t('E-mail address')),
      },
    ],
  };

  const formSchema02 = {
    fields: [
      {
        id: 'email2',
        label: t('E-mail address') + ' *',
        hint: '',
        placeholder: t('E-mail address'),
        name: 'email2',
        as: 'input',
        type: 'email',
        value: email.value || '',
        validateOnInput: true,
        validateOnMount: true,
        validateOnChange: true,
        disabled: true,
        // initialValue: 'asdfsadf',
        rules: yup.string().required(t('yup.required')).email(t('yup.email')).min(8, t('yup.min')).max(100).label(t('E-mail address')),
      },
      {
        id: 'code',
        label: t('forgotpassword.code') + ' *',
        hint: '',
        placeholder: t('forgotpassword.code'),
        name: 'code',
        as: 'input',
        type: 'text',
        validateOnInput: true,
        validateOnMount: true,
        validateOnChange: true,
        // initialValue: '',
        rules: yup.string().required(t('yup.required')).label(t('forgotpassword.code')),
      },
      {
        id: 'password',
        ref: 'password',
        label: t('New password'),
        hint: '',
        name: 'password',
        placeholder: 'password',
        as: 'input',
        type: 'password',
        validateOnChange: true,
        validateOnInput: true, 
        rules: yup.string().required(t('yup.required')).matches(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d/\W|_/g]{8,100}$/,{ message: () => t('passwordinput.error') }).label(t('New password')),
      },
      {
        id: 'passwordconfirm',
        label: t('New password again'),
        hint: '',
        name: 'passwordconfirm',
        placeholder: 'passwordconfirm',
        as: 'input',
        type: 'password',
        validateOnMount: true,
        validateOnChange: true,
        validateOnInput: true, 
        // rules: yup.string().required(t('yup.required')),
        // rules: yup.string().required(t('yup.required')).oneOf([yup.ref('password'), null], 'Passwords must match')
        // rules: yup.string().required(t('yup.required')).test('passwords-match', 'Passwords must match', function(value){ return yup.ref('password') === value }),
//rules: yup.string().oneOf([yup.ref('password'), null], `Passwords does not match: ${yup.ref('password')}`),
        // rules: yup.string().oneOf([passwordConfirm.value, null], `Passwords does not match: ${yup.ref('password')}`),
        // rules: yup.string().oneOf([passwordConfirm.value, null], 'Passwords must match'),
        // rules: yup.string().test('passwords-are-same', 'Passwords does not match', (value, context) => {
        //   console.log(value + ' VS ' + passwordConfirm.value );
        //   if(value === passwordConfirm.value) {
        //     true;
        //   }
        // }),
        rules: yup.string().required(t('yup.required')).matches(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d/\W|_/g]{8,100}$/,{ message: () => t('passwordinput.error') }).label(t('New password again')),
      },
    ],
  };

  const initialValuesForm = {};

  // állapot nullázása és az ablak bezárása:
  const closeAndResetThisModal = () => {
    process.value = 1;
    closeModal();
  };

  // "email sent" message and go to the third form page
  const gotMail = () => {
    process.value = 3;
  };

  // Ideiglenes megerősítő kódot küldünk a felhasználó e-mail címére és 
  //  átirányítjuk a következő form-hoz, ahol a kiküldött kóddal tud továbblépni.
  const requestPasswordForgotEmail = async (value) => {
    loadinginmodal.value = true;
    console.log('requestPasswordForgotEmail:', value);
    // a cognito beállítások alapján a user felhasználóneve az emailcíme:
    const username = value.email;
    // A második form email mező értékének előzetes beállítása:
    formSchema02.fields[0].value = value.email;
    try {
      // e-mail kiküldése
      const forgotpass = await forgotPassword(username);
      // console.log('forgotpass: ', forgotpass);
      alreadySent.value = true;
      process.value = 3;
      errorSummary.value = '';
    } catch (error) {
      // ha már létezik ez a user: a bejelentkezés ablakra van szükség
      // ezt csak és kizárólag egy új regisztráció indításával tudom ellenőrizni:
      if (error.code === 'LimitExceededException') {
        // LimitExceededException: Attempt limit exceeded, please try after some time.
        errorSummary.value = t('auth.forgetpassword.LimitExceededException');
      } else {
        alreadySent.value = false;
      }
    } finally {
      loadinginmodal.value = false;
    }
  };

  // Send confirmation code to user's email and go to the second form page
  const requestforgotPasswordSubmit = async (value) => {
    form02Values.value = value;
    console.log('requestforgotPasswordSubmit:', value);
    loadinginmodal.value = true;
    const username = value.email2;
    const code = value.code;
    const newPassword = value.password;
    try {
      const forgotpass = await forgotPasswordSubmit(username, code, newPassword);
      console.log(forgotpass);
      process.value = 4;
    } catch (error) {
      errorSummary.value = error.message;
    } finally {
      loadinginmodal.value = false;
    }
  };

</script>

<template>
  <Transition>
    <div
      v-show="isOpened === true"
      class="absolute top-0 left-0 w-screen h-full bg-opacity-50 z-50 xs:px-2 sm:px-1"
      style="background-color: rgba(0, 0, 0, 0.5)"
    >
      <div
        class="xs:max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl mt-16 lg:mt-52 xl:mt-52 mx-auto bg-page-dark p-2 rounded-lg shadow-lg"
      >
        <!-- loader icon -->
        <div v-show="loading" class="mx-auto text-center">
          <UiLoadSpinner v-show="loading" />
        </div>
        <div v-if="!loading" class="flex justify-between items-center">
          <!-- modal title -->
          <h2 class="text-xl text-txt-light">
            <font-awesome-icon
              :icon="['fas', 'key']"
              class="text-navtxtsecondary-dark"
            />
            {{ $t('Forgot password') }}
          </h2>
          <div>
            <!-- close modal -->
            <button
              type="button"
              class="w-8 h-8 p-2 bg-primary text-txt-light hover:shadow-sm items-center rounded-md font-sans font-light text-sm disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
              @click="closeAndResetThisModal()"
            >
              <font-awesome-icon :icon="['fas', 'times']" />
            </button>
          </div>
        </div>
        <div class="relative mt-5 flex justify-around">
          <div>
            <div
              class="w-8 h-8 mx-auto rounded-full flex items-center border border-primary"
              :class="process !== 1 ? 'text-gray-dark' : ''"
            >
              <span v-if="process > 1" class="text-center w-full">
                <font-awesome-icon :icon="['fas', 'check']" class="text-3xl" />
              </span>
              <span v-else class="text-center w-full font-sans text-sm">1</span>
            </div>
            <div
              class="text-center text-xs font-sans font-light mt-1"
              :class="process !== 1 ? 'text-gray-dark' : ''"
            >
              {{ $t('Forgot password request') }}
            </div>
          </div>
          <div>
            <div
              class="w-8 h-8 mx-auto rounded-full flex items-center border border-primary"
              :class="process !== 3 ? 'text-gray-dark' : ''"
            >
              <span v-if="process > 3" class="text-center w-full">
                <font-awesome-icon :icon="['fas', 'check']" class="text-3xl" />
              </span>
              <span v-else class="text-center w-full font-sans text-sm">2</span>
            </div>
            <div
              class="text-center text-xs font-sans font-light mt-1"
              :class="process !== 3 ? 'text-gray-dark' : ''"
            >
              {{ $t('new password setup') }}
            </div>
          </div>
        </div>
        <div class="productdetails p-2 my-5">
          <div v-if="process === 1">
            <!-- Email kuldes az itt megadott email-re az ideiglenes koddal -->
            <DynamicForm 
              :key="'forgotFormProcess1'"
              :schema="formSchema01" 
              :submitForm="requestPasswordForgotEmail" 
              :submitButtonPlaceOnThe="'center'"
              :initialValues="{}"
              :fieldPairs="{}"
              :validateOnMount="false"
              class="mx-auto bg-white shadow-md rounded-md px-8 pt-6 pb-8 m-4 flex flex-col bg-boxbg-dark"
            >
              <template v-slot:infobarbetween>
                <LazyUiInfoMessage v-if="errorSummary!==''" class="flex items-center content-center justify-center">
                  <font-awesome-icon
                    :icon="['fas', 'exclamation-circle']"
                    class="text-xl text-red-dark m-1"
                  />
                  {{ errorSummary }}
                </LazyUiInfoMessage>
                <div v-show="errorSummary === ''" class="my-5 text-txt-light text-sm font-sans font-light">
                  <p class="text-center">
                    {{ $t('forgotpassword.temporarycodeinfo') }}
                  </p>
                </div>
              </template>
              <template v-slot:submitbuttonlabel>
                <span v-if="alreadySent">
                  {{ $t('forgotpassword.sendreminderemailalreadysent') }}..
                </span>
                <span v-else>
                  {{ $t('forgotpassword.sendreminderemail') }}..
                </span>
              </template>
            </DynamicForm>
          </div>
          <div v-if="process === 3">
            <!-- E-mailben érkezett kód megadása és ellenőrzése az új jelszó beállításához -->
            <DynamicForm 
              :key="'forgotFormProcess2'"
              :schema="formSchema02" 
              :fieldChange="fieldChangeForgotFormProcess2"
              :submitForm="requestforgotPasswordSubmit" 
              :submitButtonPlaceOnThe="'center'"
              :initialValues="{}"
              :fieldPairs="{}"
              :validateOnMount="false"
              class="mx-auto bg-white shadow-md rounded-md px-8 pt-6 pb-8 m-4 flex flex-col bg-boxbg-dark"
            >
              <template v-slot:infobarbetween v-if="errorSummary">
                <UiInfoMessage v-show="errorSummary !== ''">
                  <UiInputTextErrorMessage>
                    {{ $t(errorSummary) }}
                  </UiInputTextErrorMessage>
                </UiInfoMessage>
              </template>
              <template v-slot:submitbuttonlabel>
                {{ $t('Sign in') }}
              </template>
            </DynamicForm>
          </div>
          <div v-if="process === 4">
            <UiInfoMessage class="text-center">
              {{ $t('forgotpassword.changesuccessful') }}
            </UiInfoMessage>
            <div>
              <!-- close modal -->
              <div class="my-5 text-center">
                <button
                  type="button"
                  class="mx-auto px-4 py-2 bg-primary text-txt-light hover:shadow-sm items-center rounded-md font-sans font-light text-sm disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none"
                  :class="loadinginmodal ? 'disabled' : ''"
                  @click="closeAndResetThisModal()"
                >
                  <font-awesome-icon :icon="['fas', 'times']" />
                  {{ $t('Close') }}..
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<!-- 
  watch: {
    cognitousername(newVal, oldVal) {
      console.log('newVal', newVal)
      this.authForgetPasswordForm.email = newVal
    },
  },

  forgotPassword: 'auth/forgotPassword',
  forgotPasswordSubmit: 'auth/forgotPasswordSubmit',

  closeModal: 'authforgetpasswordmodal/closeModal',
  setCognitoUsername: 'authforgetpasswordmodal/setCognitoUsername',

-->
